import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import { fontSizes } from 'utils/typography'
import SecondaryParagraph from 'components/text/secondary-paragraph'

import AddToSlackButton from 'components/buttons/add-to-slack'

// prettier-ignore
const IndexPage = () => {
  return (
    <Layout>
      <div>
        <h1>Pricing</h1>
      </div>
      <table>
        <tr>
          <th>Plan</th>
          <th>Users</th>
          <th>Price</th>
        </tr>
        <tr>
          <td>Small</td>
          <td>&le;10</td>
          <td>$39</td>
        </tr>
        <tr>
          <td>Medium</td>
          <td>&le;20</td>
          <td>$79</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>&le;25</td>
          <td>$99</td>
        </tr>
        <tr>
          <td>Extra Large</td>
          <td>&le;45</td>
          <td>$159</td>
        </tr>
        <tr>
          <td>Double XL</td>
          <td>&le;50</td>
          <td>$199</td>
        </tr>
        <tr>
          <td>Triple XL</td>
          <td>&le;100</td>
          <td>$399</td>
        </tr>
          <tr>
          <td>Enterprise</td>
          <td>&gt;100</td>
          <td><Link to="/support" target="_blank">Contact Us</Link></td>
        </tr>
      </table>
      <div>
          <AddToSlackButton size={60} />
          <SecondaryParagraph>Toast cannot read your Slack messages.</SecondaryParagraph>
      </div>
    </Layout>
  )
}

export default IndexPage
